import React, { useState, useCallback } from 'react';
import { useLocalization } from '../../../contexts/LocalizationProvider';
import { ButtonGroup } from 'devextreme-react/button-group';
import { Button } from 'devextreme-react/button';
import { mdiArrowLeftThick, mdiArrowTopLeftThick, mdiArrowUpThick, mdiArrowTopRightThick, mdiArrowRightThick, mdiArrowBottomLeftThick, mdiArrowDownThick, mdiArrowBottomRightThick } from '@mdi/js'
import { useScreenSize } from '../../../contexts/ScreenSizeProvider';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Eyes, Controls } from '../../../helpers/Constants';
import { confirm } from 'devextreme/ui/dialog';
import './visus.scss';

function getIcon(svg, width) {
    return `<svg viewBox="0 0 24 24" style="width: ${width / 2}px; height: ${width / 2}px;"><path d="${svg}"></svg>`;
}

export default function Visus({ sendMessage, loading, data, mode }) {
    const { t } = useLocalization();
    const { width: screenWidth, height: screenHeight } = useScreenSize();
    const [positions, setPositions] = useState([]);

    const width = useCallback(() => {
        if (mode == Controls.VisusDemo) {
            let size = Math.min(screenWidth, screenHeight, 600);
            size = Math.max(size, 200);
            return (size - 112) / 3;
        }
        else if (mode == Controls.Visus) {
            if (screenWidth + 114 < screenHeight) {
                let size = Math.min(screenWidth, 600);
                size = Math.max(size, 200);
                return (size - 112) / 3;
            }
            else {
                let size = Math.min(screenHeight, 714);
                size = Math.max(size, 314);
                return (size - 226) / 3;
            }
        }
    }, [screenWidth, screenHeight]);

    const onItemClick = (e) => {
        if (positions.length > 0 && e.itemData.position == positions[0]) {
            setPositions([]);
        } else {
            setPositions([e.itemData.position]);
        }
    }

    const click = () => {
        if (positions.length > 0) {
            sendMessage(JSON.stringify({ action: 'answer', value: positions[0] }));
            setPositions([]);
        }
    };

    const nextGrade = () => {
        sendMessage(JSON.stringify({ action: 'nextGrade' }));
    };

    const nextEye = () => {
        let result = confirm(data.eye == Eyes.Right ? t(100319) : t(100321), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: 'nextEye' }));
            }
        });
    };

    const exit = () => {
        let result = confirm(t(100320), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: 'exit' }));
                //setFinished(true);
            }
        });
    };

    return (
        <>

            <Toolbar>
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    visible={data && data.grade !== undefined && parseFloat(data.grade) < parseFloat(0.8)}
                    options={{ text: t(400700), onClick: nextGrade }} />
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    visible={data && data.eye == Eyes.Right}
                    options={{ text: t(400701), onClick: nextEye }} />
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    visible={data && data.eye == Eyes.Left}
                    options={{ text: t(400702), onClick: nextEye }} />
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    options={{ text: t(100311), onClick: exit }} />
            </Toolbar>
            { mode == Controls.Visus &&
                <div id="eye-wrapper">
                    {data && data.eye == Eyes.Right ? t(100318) : (data.eye == Eyes.Left ? t(100317) : (data.eye == Eyes.Binocular ? t(100322) : ""))}
                </div>
            }
            <div className={'visus-wrapper'}>
                {/*<audio id="myAudio"> // TODO VAT: neviem ci pre istotu nenechat
                    <source src="wrong.mp3" type="audio/mpeg" />
                </audio>*/}

                {/*<div>{JSON.stringify(data)}</div>*/}
                <div>
                    <ButtonGroup
                        className={'mdi-icon-button'}
                        items={[
                            { position: 5, icon: getIcon(mdiArrowTopLeftThick, width()), width: width(), height: width() },
                            { position: 6, icon: getIcon(mdiArrowUpThick, width()), width: width(), height: width() },
                            { position: 7, icon: getIcon(mdiArrowTopRightThick, width()), width: width(), height: width() }
                        ]}
                        stylingMode="outlined"
                        keyExpr="position"
                        selectedItemKeys={positions}
                        onItemClick={onItemClick}
                        disabled={loading}
                    />
                </div>
                <div style={{ margin: "-1px 0" }}>
                    <ButtonGroup
                        className={'mdi-icon-button'}
                        items={[
                            { position: 4, icon: getIcon(mdiArrowLeftThick, width()), width: width(), height: width() }
                        ]}
                        keyExpr="position"
                        stylingMode="outlined"
                        selectedItemKeys={positions}
                        onItemClick={onItemClick}
                        disabled={loading}
                    />
                    <Button
                        text={t(100116)}
                        disabled={positions.length == 0}
                        onClick={click}
                        width={width()}
                        height={width()}
                        stylingMode="outlined"
                        className={'no-padding'}
                        style={{ margin: "0 -1px 0 0", borderWidth: "1px 0", fontSize: width() / 3, minWidth: 'auto' }} />
                    <ButtonGroup
                        className={'mdi-icon-button'}
                        items={[
                            { position: 0, icon: getIcon(mdiArrowRightThick, width()), width: width() + 1, height: width() }
                        ]}
                        keyExpr="position"
                        stylingMode="outlined"
                        selectedItemKeys={positions}
                        onItemClick={onItemClick}
                        disabled={loading}
                    />
                </div>
                <div>
                    <ButtonGroup
                        className={'mdi-icon-button'}
                        items={[
                            { position: 3, icon: getIcon(mdiArrowBottomLeftThick, width()), width: width(), height: width() },
                            { position: 2, icon: getIcon(mdiArrowDownThick, width()), width: width(), height: width() },
                            { position: 1, icon: getIcon(mdiArrowBottomRightThick, width()), width: width(), height: width() }
                        ]}
                        stylingMode="outlined"
                        keyExpr="position"
                        selectedItemKeys={positions}
                        onItemClick={onItemClick}
                        disabled={loading}
                    />
                </div>
            </div>
        </>
    );
}