import * as React from 'react';
import Log from '../utils/Log';
import Error from '../views/error/Error'
import { LocalizationContext } from '../contexts/LocalizationProvider';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {    // Update state so the next render will show the fallback UI.    
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service    
        Log.error(error);
        Log.error(errorInfo);
    }

    render() {
        if (this.state.hasError) {      // You can render any custom fallback UI  
            // TODO PLM, unable to get text from db, could not be loaded
            return <Error description="Ups! Niečo sa pokazilo" text="Pracujeme na tom aby sme to čo najskôr napravili." buttonText="Späť na domovskú stránku"/>;
        } else {
            return this.props.children;
        }
    }
}
ErrorBoundary.contextType = LocalizationContext;