import React from 'react';
import { AuthenticationService } from '../../services'
import {  LocalizationContext, locale, useLocalization } from '../../contexts/LocalizationProvider';
import DropDownButton from 'devextreme-react/drop-down-button';
import skIcon from './icons/sk.svg';
import enIcon from './icons/en.svg';
import deIcon from './icons/de.svg';
import { fetchData } from '../../helpers/Fetch';
import { notify } from '../../utils/Notify';
import './languagePanel.scss';

export default function LanguagePanel() {
    const { t } = useLocalization();

    const onItemClick = (e, saveClientLocale) => {
        const reload = () => {
            window.location.reload();
        };

        if (e.itemData.id.toLowerCase() != locale()) {
            saveClientLocale(e.itemData.id);
            if (AuthenticationService.isLoggedIn()) {
                fetchData(`userApi/updateLanguage/${e.itemData.id}`, { method: 'PUT' }, true, 'text').then(() => {
                    reload();
                }).catch(error => {
                    notify(t(300006), 'error');
                });
            } else {
                reload();
            }
        }        
    }

    return (
        <LocalizationContext.Consumer>
            {({ availableLocales, saveClientLocale }) => {
                const items = availableLocales.map(o => {
                    var langIcon = enIcon;
                    switch (o.toLowerCase()) {
                        case 'sk':
                            langIcon = skIcon;
                            break;
                        case 'en':
                            langIcon = enIcon;
                            break;
                        case 'de':
                            langIcon = deIcon;
                            break;
                        default:
                            langIcon = enIcon;
                    }

                    return { name: '', id: o, icon: langIcon }
                });
                const currentLanguage = items.find(o => o.id == locale().toLowerCase());

                return (
                    <DropDownButton
                        type="normal"
                        stylingMode="contained"
                        items={items}
                        icon={currentLanguage.icon}
                        itemRender={(item) => {
                            return <img style={{ height: 18, marginLeft: 10 }} src={item.icon} />
                        }}
                        displayExpr="name"
                        keyExpr="id"
                        onItemClick={(e) => onItemClick(e, saveClientLocale)}                        
                    />
                );
            }}
        </LocalizationContext.Consumer>        
    );
}