import * as React from 'react';
import { Component } from 'react';
import Error from './Error'
import { LocalizationContext } from '../../contexts/LocalizationProvider';

export default class NotFound extends Component {
    static displayName = NotFound.name;

    render() {
        return (
            <Error code="404" description={this.context.t(300100)} text={this.context.t(300101)} />
        );
    }
}
NotFound.contextType = LocalizationContext;