import { Number } from '../../helpers';

export const TextBoxOptions = {

}

export const TextAreaOptions = {
    autoResizeEnabled: true,
    elementAttr: {
        style: "margin-top: -3px;"
    },
}

export const NumberBoxOptions = {

}

const setValue = (value, component) => {
    if (component.option('value') != value) {
        component.option('value', value);
    }
}

const updateQuarter = (value, component) => {
    if (value != null) {
        const newValue = Number.format(Number.round(value, 25 / 100), 2);
        setValue(newValue, component);
        return newValue;
    }
    return value;
}

const updateInteger = (value, component) => {
    if (value != null) {
        const newValue = Number.format(Number.round(value, 1 / 1), 0);
        setValue(newValue, component);
        return newValue;
    }
    return value;
}

const updateDistance = (value, component) => {
    if (value != null) {
        let newValue = Number.format(Number.round(value, 10 / 1), 0);
        setValue(newValue, component);
        return newValue;
    }
    return value;
}

const updateAxis = (value, component) => {
    if (value != null) {
        let newValue = Number.format(Number.round(value, 1 / 1), 0);
        if (parseInt(newValue) == 180) newValue = 0;
        setValue(newValue, component);
        return newValue;
    }
    return value;
}

const updatePd = (value, component) => {
    if (value != null) {
        let newValue = Number.format(Number.round(value, 5 / 10), 1);
        setValue(newValue, component);
        return newValue;
    }
    return value;
}

export const AxisNumberBoxOptions = (onFocusOut) => {
    return {
        step: 1,
        onFocusOut: (e) => {
            const value = updateAxis(e.component.option('value'), e.component);
            if(onFocusOut) onFocusOut(value);
        }
    };
}

export const SphNumberBoxOptions = (onFocusOut) => {
    return {
        step: 0.25,
        onFocusOut: (e) => {
            const value = updateQuarter(e.component.option('value'), e.component);
            if(onFocusOut) onFocusOut(value);
        }
    };
}

export const CylNumberBoxOptions = (onFocusOut) => {
    return {
        step: 0.25,
        onFocusOut: (e) => {
            const value = updateQuarter(e.component.option('value'), e.component);
            if (onFocusOut) onFocusOut(value);
        }
    };
}

export const PdNumberBoxOptions = (onFocusOut) => {
    return {
        step: 0.5,
        onFocusOut: (e) => {
            const value = updatePd(e.component.option('value'), e.component);
            if (onFocusOut) onFocusOut(value);
        }
    };
}

export const AddNumberBoxOptions = (onFocusOut) => {
    return {
        step: 0.25,
        onFocusOut: (e) => {
            const value = updateQuarter(e.component.option('value'), e.component);
            if (onFocusOut) onFocusOut(value);
        }
    };
}

export const IntegerNumberBoxOptions = (onFocusOut) => {
    return {
        step: 1,
        onFocusOut: (e) => {
            const value = updateInteger(e.component.option('value'), e.component);
            if(onFocusOut) onFocusOut(value);
        }
    };
}

export const DistanceNumberBoxOptions = (onFocusOut) => {
    return {
        step: 10,
        onFocusOut: (e) => {
            const value = updateDistance(e.component.option('value'), e.component);
            if(onFocusOut) onFocusOut(value);
        }
    };
}

export const AutocompleteOptions = {
    elementAttr: {
        style: "margin-top: -1px;"
    },
    height: 34
}

export const SelectBoxOptions = {
    placeholder: ''
}

export const DropDownBoxOptions = {

}

export const DateBoxOptions = {

}

export const CheckBoxOptions = {
    elementAttr: {
        style: "padding-top: 6px; padding-bottom: 6px;",
    }
}
export const NullableCheckBoxOptions = {
    ...CheckBoxOptions, ...{
        /*onValueChanged(e) {
            if (e.component.skipOnValueChanged) {
                e.component.skipOnValueChanged = false;
                return;
            }
            if (e.component.setUndefinedNextTime) {
                e.component.setUndefinedNextTime = false;
                e.component.skipOnValueChanged = true;
                e.component.option("value", undefined);
                return;
            }
            if (e.value == false) {
                e.component.setUndefinedNextTime = true;
            }
        },*/
        onInitialized(e) {
            if (e.component.option('value') == null) {
                e.component.option("value", undefined);
            }
            /*if (e.component.option('value') == false) {
                e.component.setUndefinedNextTime = true;
            }*/

            // TODO PLM nefunguje dobre
        }
    }
}