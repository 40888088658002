import React from 'react';
import ContentTitle from '../contentTitle/ContentTitle';
import { LocalizationContext } from '../../contexts/LocalizationProvider';
import { fetchData } from '../../helpers/Fetch';
import Box, { Item } from 'devextreme-react/box';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { notify } from '../../utils/Notify';
import Log from '../../utils/Log';
import { ScreenSizeContext } from '../../contexts/ScreenSizeProvider';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';
import { history } from '../../helpers/History';
import { Basename, ApiActions } from '../../helpers/Constants';
import Form from 'devextreme/ui/form';
import { isDate, formatISO } from 'date-fns';
import './edit.scss';

export const DefaultFormOptions = {
    id: 'form',
    showColonAfterLabel: false,
    labelLocation: 'top',
    minColWidth: 800,
    colCount: 'auto',
    validationGroup: 'editFormValidationGroup',
    elementAttr: {
        style: 'max-width: 800px'
    }
};

export const DefaultTabFormOptions = {
    ...DefaultFormOptions, ...{ elementAttr: { style: 'max-width: 800px; padding-top: 10px;' } }
};

export const Mode = {
    create: 0,
    edit: 1
}

export default class Edit extends React.PureComponent {
    initialized = false

    constructor(props) {
        super(props);

        const mode = props.id === undefined ? Mode.create : Mode.edit;

        this.state = {
            id: props.id,
            data: mode == Mode.create ? props.defaultData || {} : {},
            originalData: mode == Mode.create ? props.defaultData || {} : {},
            loading: false,
            savingSave: false,
            savingApply: false,
            mode: mode,
            selectedTab: null
        };

        this.submit = this.submit.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setSavingSave = this.setSavingSave.bind(this);
        this.setSavingApply = this.setSavingApply.bind(this);
        this.submit = this.submit.bind(this);
        this.saveData = this.saveData.bind(this);
        this.getInstance = this.getInstance.bind(this);
        this.isSaving = this.isSaving.bind(this);
        this.isLoading = this.isLoading.bind(this);
        this.isBusy = this.isBusy.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.tabChanged = this.tabChanged.bind(this);    
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyPress, false);

        if (this.state.mode === Mode.edit) {
            this.setLoading(true);

            var query = `${this.props.loadUrl}/${this.state.id}`;

            fetchData(query).then((data) => {
                this.setState({
                    data: data,
                    originalData: { ...data, ...{} },
                    loading: false
                });
                if (this.props.afterLoad) this.props.afterLoad(data);
            }).catch((error) => {
                this.setLoading(false);
                Log.error(error);
                notify(this.context.t(300005), 'error');
                this.props.cancel(this.state.id, this.state.data);
            });
        } else {
            if (this.props.afterLoad) this.props.afterLoad(this.state.data);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyPress, false);
    }

    keyPress(e) {
        if (e.keyCode === 27) {
            this.props.cancel(this.state.id, this.state.data);
        }
    }

    setLoading(value) {
        this.setState({ loading: value });
    }

    setSavingSave(value) {
        this.safeScrollAction(() => { this.setState({ savingSave: value }); });
    }

    setSavingApply(value) {
        this.safeScrollAction(() => { this.setState({ savingApply: value }); });
    }

    safeScrollAction(action) {
        if (this.scrollView) {
            const scrollOffset = this.scrollView.scrollOffset();
            action();
            this.scrollView.scrollTo(scrollOffset);
        } else {
            action();
        }
    }

    submit(e, navigateBack, setSavingFunc) {
        const doSubmit = (validation) => {
            // skip hidden elements
            const brokenRules = validation.brokenRules.filter(rule => rule.validator.element().offsetParent != null);
            if (brokenRules.length == 0) {
                this.saveData(setSavingFunc).then(() => {
                    if (this.props.submitSuccess) this.props.submitSuccess(this.state.id, this.state.data);
                    if (navigateBack) {
                        this.props.cancel(this.state.id, this.state.data);
                    } else {
                        notify(this.context.t(100202), 'success');
                    }
                }).catch((error) => {
                    Log.error(error);
                    notify(this.context.t(300006), 'error');
                });
            } else {
                brokenRules[0].validator.focus();
                if (this.scrollView) {
                    this.scrollView.update();
                    this.scrollView.scrollBy({left:0, top: -40});
                }

                // unable to show more notifications in same time
                /*for (let i = 0; i < validation.brokenRules.length; i++) {
                    notify(validation.brokenRules[i].message, 'error');
                }*/
            }
        };

        this.initialized = true;
        const validation = e.validationGroup.validate();
        if (validation.complete) {
            validation.complete.then((validation) => {
                doSubmit(validation);
            });
        } else {
            doSubmit(validation);
        }
    };

    saveData(setSavingFunc) {
        return new Promise((resolve, reject) => {
            setSavingFunc(true);

            let jsonData = {};
            for (const property in this.state.data) {
                jsonData[property] = isDate(this.state.data[property]) ? formatISO(this.state.data[property]) : this.state.data[property];
            }

            if (this.state.mode === Mode.create) {
                var query = `${this.props.saveUrl}`;
                fetchData(query, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jsonData)
                }).then((data) => {
                    setSavingFunc(false);
                    this.safeScrollAction(() => {
                        this.setState({
                            id: data[this.props.keyFieldName],
                            mode: Mode.edit,
                            data: data,
                            originalData: { ...data, ...{} }
                        });

                        let pathname = window.location.pathname;
                        const prefix = Basename;
                        if (prefix.length > 0 && pathname.indexOf(prefix) === 0) {
                            pathname = pathname.slice(prefix.length);
                        }
                        history.push(`${pathname}/${this.state.id}`);
                    });
                    resolve();
                }).catch((error) => {
                    setSavingFunc(false);
                    reject();
                }).finally(() => {
                    // NOTE PLM setSavingFunc is moved before history.push, otherwise component was already unmounted and we tried to set state
                    //setSavingFunc(false); 
                });
            } else {
                var query = `${this.props.saveUrl}/${this.state.id}`;
                fetchData(query, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jsonData)
                }, true, 'text').then(() => {
                    this.safeScrollAction(() => {
                        this.setState({
                            originalData: { ...jsonData, ...{}}
                        });
                    });
                    resolve();
                }).catch((error) => {
                    reject();
                }).finally(() => {
                    setSavingFunc(false);
                });
            }
        });
    }

    getPadding(isXSmall, isSmall, respectTabbed = true) {
        return respectTabbed && this.props.tabbed ? 0 : (isXSmall || isSmall ? 20 : 40);
    }

    getMargin(isXSmall, isSmall) {
        return isXSmall ? 0 : (isSmall ? 20 : 40);
    }

    getHeight(height, isXSmall, isSmall) {
        // height - header - title - margin bottom
        return height - 56 - (isXSmall ? 56 : (isSmall ? 66 : 76)) - this.getMargin(isXSmall, isSmall);
    }

    getInstance(ref) {
        this.scrollView = ref ? ref.instance : null;
    }

    isSaving() {
        return this.state.savingSave || this.state.savingApply;
    }

    isLoading() {
        return this.state.loading || this.props.loading;
    }

    isBusy() {
        return this.isSaving() || this.isLoading();
    }

    getSnapshotBeforeUpdate() {
        return {
            focusedElement: document.activeElement
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            if (snapshot.focusedElement.tagName === 'BODY' && !this.initialized) {
                // focus first element
                const el = document.getElementById('form');
                const form = Form.getInstance(el);
                if (form && form.getEditor('default')) form.getEditor('default').focus();
            } else {
                // focus last focused element
                snapshot.focusedElement.focus();
            }
        }, 500);
    };

    tabChanged(item) {
        if (this.state.selectedTab == null || item == null || this.state.selectedTab.tabId != item.tabId) {
            this.setState({
                selectedTab: item
            });
        }        
    };

    render() {
        const form = React.cloneElement(this.props.form, {
            loading: this.isBusy(),
            id: this.state.id,
            data: this.state.data,
            originalData: this.state.originalData,
            tabChanged: this.tabChanged,
            mode: this.state.mode
        });

        if (this.props.setData) this.props.setData(this.state.data);

        return (
            <>
                <ContentTitle title={this.props.title} />
                <ScreenSizeContext.Consumer>
                    {({ height, isXSmall, isSmall }) => (
                        <div
                            className={'dx-card wide-card edit-card'}
                            style={{ height: this.getHeight(height, isXSmall, isSmall) }}
                        >
                            <ScrollView ref={this.getInstance}>
                                <div
                                    className="edit-form"
                                    style={{ paddingTop: this.getPadding(isXSmall, isSmall), paddingLeft: this.getPadding(isXSmall, isSmall), paddingRight: this.getPadding(isXSmall, isSmall), height: this.getHeight(height, isXSmall, isSmall) }}
                                >
                                    <Box
                                        className="edit-form-content"
                                        direction="row"
                                        width="100%">
                                        <Item ratio={1}>
                                            <LoadPanel
                                                position={{ of: '.edit-form' }}
                                                shading={false}
                                                showPane={false}
                                                visible={this.isLoading()}
                                            />
                                            {form}
                                        </Item>
                                    </Box>
                                    <Box
                                        className="edit-form-footer"
                                        direction="row"
                                        width="100%"
                                        style={{ paddingBottom: this.getPadding(isXSmall, isSmall, false), paddingLeft: this.props.tabbed ? this.getPadding(isXSmall, isSmall, false) : 0, paddingRight: this.props.tabbed ? this.getPadding(isXSmall, isSmall, false) : 0 }}
                                        visible={this.state.selectedTab == null || !this.state.selectedTab.hasOwnProperty('hideFooter') || !this.state.selectedTab.hideFooter}
                                    >
                                        <Item ratio={1}>
                                            <div>
                                                <Button
                                                    className="edit-form-button"
                                                    onClick={(e) => this.submit(e, true, this.setSavingSave)}
                                                    disabled={this.isBusy()}
                                                    type="default"
                                                    width="auto"
                                                    validationGroup={DefaultFormOptions.validationGroup}
                                                >
                                                    <LoadIndicator className="button-indicator" height={25} width={25} visible={this.state.savingSave} />
                                                    <span className="dx-button-text">{this.context.t(100107)}</span>
                                                </Button>
                                                <Button
                                                    className="edit-form-button"
                                                    onClick={(e) => this.submit(e, false, this.setSavingApply)}
                                                    disabled={this.isBusy()}
                                                    type="default"
                                                    width="auto"
                                                    validationGroup={DefaultFormOptions.validationGroup}
                                                >
                                                    <LoadIndicator className="button-indicator" height={25} width={25} visible={this.state.savingApply} />
                                                    <span className="dx-button-text">{this.context.t(100106)}</span>
                                                </Button>
                                                <Button
                                                    className="edit-form-button"
                                                    onClick={() => this.props.cancel(this.state.id, this.state.data)}
                                                    disabled={this.isBusy()}
                                                    width="auto"
                                                >
                                                    <span className="dx-button-text">{this.context.t(100108)}</span>
                                                </Button>
                                            </div>
                                        </Item>
                                    </Box>
                                </div>
                            </ScrollView>
                        </div>
                    )}
                </ScreenSizeContext.Consumer>
            </>    
        );
    }
}
Edit.contextType = LocalizationContext;