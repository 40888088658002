import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr'
import { useLocalization } from '../../contexts/LocalizationProvider';
import { fetchData } from '../../helpers/Fetch';
import Log from '../../utils/Log';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';
import { alert, confirm } from 'devextreme/ui/dialog';
import { ExaminationStatuses, HubUrl, Controls } from '../../helpers/Constants';
import Optotype from './optotype/OptotypeRemote';
import Duochromatic from './duochromatic/DuochromaticRemote';
import Cylinder from './cylinder/CylinderRemote';
import Visus from './visus/VisusRemote';
import { HubConnectionState } from '@microsoft/signalr'
import { notify } from '../../utils/Notify';
import './remote.scss';

function NotConnected() {
    const { t } = useLocalization();
    return (
        <>
            <div>{t(300501)}</div>
        </>
    );
}

function Finished() {
    const { t } = useLocalization();
    return (
        <>
            <div>{t(100309)}</div>
        </>
    );
}

function Expired() {
    const { t } = useLocalization();
    return (
        <>
            <div>{t(100315)}</div>
        </>
    );
}

export default function Remote() {
    const { t } = useLocalization();
    const { examinationId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [expired, setExpired] = useState(false);
    const [data, setData] = useState({});

    const [finished, setFinished] = useState(false);
    const finishedRef = useRef();
    finishedRef.current = finished;

    const [examination, setExamination] = useState(null);
    const examinationRef = useRef();
    examinationRef.current = examination;

    const [connection, setConnection] = useState(null);
    const connectionRef = useRef();
    connectionRef.current = connection;

    const [connectionId, setConnectionId] = useState(null);
    const connectionIdRef = useRef();
    connectionIdRef.current = connectionId;

    useEffect(() => {
        setLoading(true);

        const handleError = (error, type) => {
            setError(true);
            setLoading(false);
            setExamination(null);
            setConnection(null);
            if (type == ExaminationStatuses.Expired) setExpired(true);
            if (type == ExaminationStatuses.Finished) setFinished(true);
            if (error) Log.error(error);
        };

        fetchData(`remoteApi/initialize/${examinationId}`).then((examination) => {
            if (examination != null) {
                setExamination(examination);
                const connection = new HubConnectionBuilder()
                    .withUrl(HubUrl)
                    .build();
                if (connection != null) {
                    connection.on('Connected', (connectionId) => onConnected(connectionId, connection, examination));
                    connection.onclose((e) => {
                        if (!finishedRef.current) notify(t(300506), 'error');
                    });
                    connection.on('Message', onMessage);

                    connection.start().then(() => {
                        setConnection(connection);
                        setLoading(false);
                    }).catch((error) => {
                        handleError(error);
                    });
                } else {
                    handleError();
                }
            } else {
                handleError();
            }            
        }).catch((error) => {
            error.json().then(obj => {
                if (obj.hasOwnProperty('type')) {
                    handleError(error, obj.type);
                } else {
                    handleError(error);
                }
            }).catch(() => {
                handleError(error);
            });
        });
    }, []);

    const onConnected = (connectionId, connection, examination) => {
        setConnectionId(connectionId);
        invoke(connection, "ClientConnected", examination.connectionId, connectionId, examination.controlId);
        //connection.invoke("ClientConnected", examination.connectionId, connectionId, examination.controlId);
    };

    const onMessage = (senderConnectionId, data) => {
        const message = JSON.parse(data);
        if (message.action == 'vibrate') {
            vibrate(message.value);
        } else if (message.action == 'alert') {
            alert(t(message.value), t(100054));
        } else if (message.action == 'confirm') {
            let result = confirm(t(message.value), t(100055));
            result.then((dialogResult) => {
                sendMessage(JSON.stringify({ action: 'confirmSaveResult', value: dialogResult }));
            });
        } else if (message.action == 'finish') {
            setFinished(true);
            stopConnection(connectionRef.current);
        } else if (message.action == 'setData') {
            setData(JSON.parse(message.value));
        }
    };

    const vibrate = (delay) => { // TODO VAT: neviem ci pre istotu nenechat 
        /*const el = document.getElementById('remote-wrapper');
        el.classList.add("incorrect");
        setTimeout(() => {
            el.classList.remove("incorrect");
        }, 500);*/
        navigator.vibrate(delay);

        //document.getElementById("myAudio").play(); 
    }

    const stopConnection = (connection) => {
        if (connection != null) {
            connection.stop().then(() => {
                console.log('Connection closed');
            }).catch(() => {
                console.log('Unable to close connection');
            });
        } else {
            console.log('Connection already closed');
        }
    } 

    const invoke = (connection, ...props) => {
        if (connection != null && (connection.state == HubConnectionState.Connecting || connection.state == HubConnectionState.Connected)) {
            connection.invoke(...props).catch(e => {
                console.log(e)
            });
        } else {
            //if (connection != null) window.alert(connection.state);
            notify(t(300506), 'error');
        }
    }    

    const sendMessage = (message) => {
        invoke(connectionRef.current, "Message", examinationRef.current.connectionId, connectionIdRef.current, message);
        //connection.invoke("Message", examination.connectionId, connectionId, message);
    };

    const getControl = () => {
        switch (examination.controlId) {
            case Controls.VisusDemo:
                return <Visus sendMessage={sendMessage} loading={loading} data={data} mode={examination.controlId} />
            case Controls.Visus:
                return <Visus sendMessage={sendMessage} loading={loading} data={data} mode={examination.controlId} />
            case Controls.Duochromatic:
                return <Duochromatic sendMessage={sendMessage} loading={loading} data={data} />
            case Controls.Optotype:
                return <Optotype sendMessage={sendMessage} loading={loading} />
            case Controls.Cylinder:
                return <Cylinder sendMessage={sendMessage} loading={loading} data={data} />
            default:
                break;
        }
    }

    return (
        <>
            <LoadPanel
                position={{ of: '.app' }}
                visible={loading}
                showIndicator={true}
                shading={false}
                showPane={false}
            />
            <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'} direction={examination ? (examination.controlId == Controls.Optotype ? "vertical" : "both") : "vertical"}>
                <div id="remote-wrapper" className={examination ? (examination.controlId != Controls.Optotype || finished ? "not-optotype" : "") : ""}>
                    {error
                        ? (finished ? <Finished /> : (expired ? <Expired /> : <NotConnected />))
                        : (connection != null && (!finished ? getControl() : <Finished />))
                    }
                </div>
            </ScrollView>            
        </>
    );
}
