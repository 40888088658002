import React, { useEffect, useRef, useCallback } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import { withRouter } from 'react-router';
import './sideNavigationMenu.scss';

import * as events from 'devextreme/events';

function SideNavigationMenu(props) {
    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady,
        menuItems,
        location,
        view
    } = props;

    const { isLarge, isXLarge } = useScreenSize();

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (location.pathname !== undefined) {
            const findActiveItem = (arr, nestingKey) => (
                arr.reduce((a, item) => {
                    if (a) return a;
                    if (item.itemData.route && location.pathname.startsWith(`/${item.itemData.route.path}`)) return item;
                    if (item[nestingKey]) return findActiveItem(item[nestingKey], nestingKey)
                }, null)
            );

            const activeItem = findActiveItem(treeView.getNodes(), 'items');
            if (activeItem) {
                treeView.selectItem(activeItem.key);
                treeView.expandItem(activeItem.key);
            } else {
                treeView.unselectAll();
            }
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [location, view, compactMode]);

    return (
        <div
            className={'dx-swatch-vizweb-dark side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={menuItems.map(item => ({ ...item, expanded: isLarge || isXLarge }))}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    );
}

export default withRouter(SideNavigationMenu)