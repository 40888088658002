import React, { useCallback } from 'react';
import { useLocalization } from '../../../contexts/LocalizationProvider';
import { Button } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../../contexts/ScreenSizeProvider';
import { Eyes } from '../../../helpers/Constants';
import { confirm } from 'devextreme/ui/dialog';
import './duochromatic.scss';

export default function DuochromaticRemote({ sendMessage, loading, data }) {
    const { t } = useLocalization();
    const { width: screenWidth, height: screenHeight } = useScreenSize();

    const exit = () => {
        let result = confirm(t(100320), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: 'exit' }));
            }
        });
    };

    const minus = () => {
        sendMessage(JSON.stringify({ action: "minus" }));
    }

    const plus = () => {
        sendMessage(JSON.stringify({ action: "plus" }));
    }

    const overlap = () => {
        sendMessage(JSON.stringify({ action: "changeOverlap" }));
    }

    const done = () => {
        let result = confirm(data.eye == Eyes.Right ? t(100319) : t(100321), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: "nextEye" }));
            }
        });
    }

    const width = useCallback(() => {
        let size = Math.min(screenWidth, 450);
        size = Math.max(size, 320);
        return (size - 212);
    }, [screenWidth]);

    return (
        <>
            <Toolbar>
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    options={{ text: t(100311), onClick: exit }} />
            </Toolbar>

            <div id="duochromatic-wrapper">
                <div id="eye-wrapper">{data && data.eye == Eyes.Right ? t(100318) : (data.eye == Eyes.Left ? t(100317) : "")}</div>
                <div id="axis-wrapper">
                    <Button
                        onClick={minus}
                        type="default"
                        icon="minus"
                        disabled={loading}
                    />
                    <div id="axis-value" style={{ width: width() }}>{data && data.axis !== undefined && data.axis + "°"}</div>
                    <Button
                        onClick={plus}
                        type="default"
                        icon="plus"
                        disabled={loading}
                    />
                </div>
                <Button
                    onClick={overlap}
                    type="default"
                    width="auto"
                    text={t(401014)}
                    disabled={loading}
                />
                <Button
                    onClick={done}
                    type="default"
                    width="auto"
                    text={t(401015)}
                    disabled={loading}
                />
            </div>
        </>
    )
}