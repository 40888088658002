import * as React from 'react';
import { Component } from 'react';
import { AuthenticationService } from '../services';
import { QueryParameterNames, Basename, StorageKeys } from '../helpers/Constants';
import { Miscellaneous } from '../helpers';
import { SingleCard } from '../layouts';
import { withRouter } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { LocalizationContext, locale } from '../contexts/LocalizationProvider';
import { confirm } from 'devextreme/ui/dialog';
import { notify } from '../utils/Notify';
import Log from '../utils/Log';

class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        let data = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.Login, false)) != null ? JSON.parse(localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.Login, false))) : null;
        this.state = {
            loading: false,
            formData: {
                login: data ? data.login : '',
                password: '',
                language: locale().toUpperCase(),
                rememberMe: data ? data.rememberMe == 'true' : false
            }
        };

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getReturnUrl = this.getReturnUrl.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
    }

    componentDidMount() {
        if (AuthenticationService.isLoggedIn()) {
            this.props.history.push(this.getReturnUrl());
        }

        
    }

    onInitialized(e) {
        setTimeout(() => {
            let editor = null;
            if (this.state.formData.login == '') {
                editor = e.component.getEditor('login');
            } else if (this.state.formData.password == '') {
                editor = e.component.getEditor('password');
            } else {
                editor = e.component.getButton('login');
            }
            if(editor !== undefined) editor.focus();
        }, 500);
    }

    getReturnUrl() {
        const searchParams = new URLSearchParams(window.location.search);
        let returnUrl = this.props.defaultRoute;
        if (searchParams.has(QueryParameterNames.ReturnUrl)) returnUrl = searchParams.get(QueryParameterNames.ReturnUrl);
        return returnUrl;
    }

    login(e) {
        e.preventDefault();

        this.setState({ loading: true });

        const { login, password, rememberMe } = this.state.formData;

        AuthenticationService.login(login, password, rememberMe).then(() => {
            const redirect = () => {
                window.location.replace(`${Basename}${this.getReturnUrl()}`);
            }

            // do not store password
            let doNotSaveLogin = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.DoNotSaveLogin, false)) == 'true';
            if (!doNotSaveLogin) {
                let oldData = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.Login, false));
                let newData = JSON.stringify({ login: login, rememberMe: rememberMe ? 'true' : 'false' });
                if (oldData != newData) {
                    let result = confirm(this.context.t(100201), this.context.t(100055));
                    result.then((dialogResult) => {
                        // save credentials
                        if (dialogResult) {
                            localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.Login, false), newData);
                        } else {
                            localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.DoNotSaveLogin, false), 'true');
                            localStorage.removeItem(Miscellaneous.getStorageKey(StorageKeys.Login, false));
                        }
                        redirect();
                    });
                } else {
                    redirect();
                }
            } else {
                redirect();
            }
        }).catch((error) => {
            this.setState({ loading: false });
            notify(this.context.t(300001), 'error');
        });
    }

    logout() {
        AuthenticationService.logout().then(data => {
            Log.trace(data);
        }).catch((error) => {
            Log.error(error);
        });
    }

    render() {
        return (
            <LocalizationContext.Consumer>
                {({ t, availableLocales }) => {
                    return (
                        <SingleCard title={t(400000)}>
                            <form className={'login-form'} onSubmit={this.login}>
                                <Form formData={this.state.formData} disabled={this.state.loading} onInitialized={this.onInitialized}>
                                    <Item
                                        dataField={'login'}
                                        editorType={'dxTextBox'}
                                        editorOptions={{ stylingMode: 'filled', placeholder: t(400001) }}
                                    >
                                        <RequiredRule message={t(300000)} />
                                        <Label visible={false} />
                                    </Item>
                                    <Item
                                        dataField={'password'}
                                        editorType={'dxTextBox'}
                                        editorOptions={{ stylingMode: 'filled', placeholder: t(400002), mode: 'password' }}
                                    >
                                        <Label visible={false} />
                                    </Item>
                                    <Item
                                        dataField={'rememberMe'}
                                        editorType={'dxCheckBox'}
                                        editorOptions={{ text: t(400003), elementAttr: { class: 'form-text' } }}
                                    >
                                        <Label visible={false} />
                                    </Item>
                                    <ButtonItem name="login">
                                        <ButtonOptions
                                            width={'100%'}
                                            type={'default'}
                                            useSubmitBehavior={true}
                                        >
                                            <span className="dx-button-text">
                                                {
                                                    this.state.loading
                                                        ? <LoadIndicator height={25} width={25} visible={true} style={{ marginTop: -4 }} />
                                                        : t(100056)
                                                }
                                            </span>
                                        </ButtonOptions>
                                    </ButtonItem>
                                </Form>
                            </form>   
                        </SingleCard>                                  
                    )
                }}
            </LocalizationContext.Consumer>
        );
    }
}
Login.contextType = LocalizationContext;
export default withRouter(Login);