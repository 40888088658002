import * as React from 'react';
import { Redirect, Route, Switch, Router as ReactRouter, useRouteMatch } from "react-router-dom";
import AuthorizeRoute from './AuthorizeRoute';
import Login from '../../views/Login';
import Logout from '../../views/Logout';
import NotFound from '../../views/error/NotFound';
import { SideNav, Default } from '../../layouts';
import { useLocalization } from '../../contexts/LocalizationProvider';
import { Actions } from '../../helpers/Constants';
import { AuthenticationService } from '../../services';
import { history } from '../../helpers/History';
import { Miscellaneous } from '../../helpers';
import SubRouter from './SubRouter';
import { LoadPanel } from 'devextreme-react/load-panel';
import Remote from '../../views/test/Remote';

// views
const PriceList = React.lazy(() => import('../../views/pricelist/PriceList'));
const Services = React.lazy(() => import('../../views/services/Services'));
const Contact = React.lazy(() => import('../../views/contact/Contact'));
const Calibration = React.lazy(() => import('../../views/calibration/Calibration'));
const Company = React.lazy(() => import('../../views/company/Company'));
const TestVisusDemo = React.lazy(() => import('../../views/test/visusdemo/VisusDemo'));
const TestVisus = React.lazy(() => import('../../views/test/visus/Visus'));
const TestDuochromatic = React.lazy(() => import('../../views/test/duochromatic/Duochromatic'));
const TestOptotype = React.lazy(() => import('../../views/test/optotype/Optotype'));
const TestCylinder = React.lazy(() => import('../../views/test/cylinder/Cylinder'));
const HistoryTest = React.lazy(() => import('../../views/history/test/HistoryTest'));
const HistoryVisus = React.lazy(() => import('../../views/test/visus/VisusHistory'));

function LazyLoadPanel() {
    return (
        <LoadPanel
            position={{ of: '.dx-drawer-content' }}
            visible={true}
            showIndicator={true}
            shading={false}
            showPane={false}
        />
    );
}

function Router({ routes, topMenuItems, sideMenuItems, ...restProps }) {
    const { t } = useLocalization();

    const findFirstRoute = (menuItems) => (
        menuItems.reduce((a, o) => {
            if (a) return a;
            if (o.route && o.route.path) return o.route;
            if (o.items) return findFirstRoute(o.items);
        }, null)
    );

    const getMenuItem = (menuItems, routeName) => (
        menuItems.reduce((a, o) => {
            if (a) return a;
            if (o.route && o.route.name === routeName) return o;
            if (o.items) return getMenuItem(o.items);
        }, null)
    );

    // from menu (only authorized are in collection)
    const defaultRoute = findFirstRoute([...sideMenuItems, ...topMenuItems]);

    // login is default page
    const defaultRoutePath = AuthenticationService.isLoggedIn() ? (defaultRoute ? `/${defaultRoute.path}` : Actions.Logout) : Actions.Login;
    //const defaultRoutePath = defaultRoute ? `/${defaultRoute.path}` : (AuthenticationService.isLoggedIn() ? Actions.Logout : Actions.Login);

    // from menu (only authorized are in collection)
    const redirectRoutes = [];
    for (let i = 0; i < sideMenuItems.length; i++) {
        let o = sideMenuItems[i];
        if (o.route && o.route.path && o.items.length > 0) {
            let route = findFirstRoute(o.items);
            if (route) {
                //debugger;
                //redirectRoutes.push({ from: `/${o.route.path}`, to: `/${o.route.path}/${route.path}` });
                redirectRoutes.push({ from: `/${o.route.path}`, to: `/${route.path}` });
            }
        }
    }
    const redirectList = redirectRoutes.map(o => (
        <Redirect key={o.from} exact from={o.from} to={o.to} />
    ));

    // components
    const components = {
        pricelist: <PriceList />,
        services: <Services />,
        contact: <Contact />,
        calibration: <Calibration />,
        company: <Company />,
        test: {
            visusdemo: <TestVisusDemo />,
            visus: <TestVisus />,
            duochromatic: <TestDuochromatic />,
            optotype: <TestOptotype />,
            cylinder: <TestCylinder />
        },
        history: {
            test: <HistoryTest />,
            visus: <HistoryVisus />
        }
    };

    // from routes (all records are in collection)
    const routeList = routes.map(o => {
        let subComponents = components[o.path];
        return <AuthorizeRoute key={o.path} path={`/${o.path}`} control={o.controls} requireAuthentication={o.requireAuthentication} {...restProps} render={(props) => (
            subComponents
                ? (AuthenticationService.isLoggedIn()
                    ? <SideNav {...props} menuItem={getMenuItem([...topMenuItems, ...sideMenuItems], o.name)} sideMenuItems={sideMenuItems} topMenuItems={topMenuItems} view={o.path} component={<SubRouter route={o} components={subComponents} />} />
                    : <Default {...props} topMenuItems={topMenuItems} component={<SubRouter route={o} components={subComponents} />} />)
                : <NotFound />
        )} />
    });

    return (
        <ReactRouter history={history}>
            <Switch>
                <Redirect exact from="/" to={defaultRoutePath} />
                {redirectList}
                {routeList}
                <Route path={Actions.Login}>
                    <Default topMenuItems={topMenuItems} component={<Login defaultRoute={defaultRoutePath} />} />
                </Route>
                <Route path={Actions.Logout}>
                    <Logout />
                </Route>
                <Route path="/remote/:examinationId/:language?">
                    <Remote />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </ReactRouter>
    )
};
export default React.memo(Router, Miscellaneous.isEqual)