import * as React from 'react';
import { Component } from 'react';
import Error from './Error'
import { LocalizationContext } from '../../contexts/LocalizationProvider';

export default class NotAuthorized extends Component {
    static displayName = NotAuthorized.name;

    render() {
        return (
            <Error code="403" description={this.context.t(300102)} text={this.context.t(300103)} />
        );
    }
}
NotAuthorized.contextType = LocalizationContext;