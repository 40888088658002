import { UPDATE_CREDIT } from "../ActionTypes";

const initialState = {
    value: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CREDIT: {
            const { value } = action.payload;
            return { ...state, value: value };
        }
        default:
            return state;
    }
}
