import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import SideNavigationMenu from '../../components/sideNavigationMenu/SideNavigationMenu';
import Header from '../../components/header/Header';
import './sideNav.scss';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../helpers/Patches';
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import NotFound from '../../views/error/NotFound';

export default function ({ view, menuItem, sideMenuItems, topMenuItems, component }) {
    const scrollViewRef = useRef();
    const history = useHistory();
    const { isXSmall, isSmall, isLarge, isXLarge, height } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(
        isLarge || isXLarge ? MenuStatus.Opened : MenuStatus.Closed
    );
    const { path, url } = useRouteMatch();

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        );
        event.stopPropagation();
    }, []);

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        );
    }, []);

    const onOutsideClick = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge && !isXLarge 
                ? MenuStatus.Closed
                : prevMenuStatus
        );
    }, [isLarge, isXLarge]);

    const onNavigationChanged = useCallback(({ itemData: { route }, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !route || !route.path) {
            event.preventDefault();
            return;
        }

/*const findFirstRoute = (arr) => (
            arr.reduce((a, o) => {
                if (a) return a;
                if (o.path === (path)) return o;
                if (o.items) return findFirstRoute(o.items);
            }, null)
        );*/

        //findFirstRoute(sideMenuItems)

        /*console.log(sideMenuItems);
        debugger;*/
        //const newPath = `/${menuItem.route.path}/${route.path}`;
        const newPath = `/${route.path}`;

        if (node.selected && history && history.location && history.location.path && history.location.pathname === newPath) {
            event.preventDefault();
            return;
        }

        history.push(newPath);
        if (scrollViewRef && scrollViewRef.current) scrollViewRef.current.instance.scrollTo(0);

        if ((!isLarge && !isXLarge) || menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed);
            event.stopPropagation();
        }
    }, [history, menuStatus, isLarge, isXLarge]);

    // NOTE PLM: fix when resizing window
    setTimeout(function () {
        let el = document.getElementsByClassName('dx-overlay-wrapper dx-drawer-panel-content');
        if (el.length > 0) {
            el = el[0];
            el.style.height = (height - 56) + 'px';
        }
    }, 100);

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                className={'layout-header'}
                menuToggleEnabled
                toggleMenu={toggleMenu}
                title="VizWeb"
                menuItems={topMenuItems}
            />
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge || isXLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 60}
                maxSize={250}
                shading={isLarge || isXLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={'menu'}
                onInitialized={(component, element) => {
                    //debugger;
                }}
            >
                <div className={'container'}>
                    <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
                        <div className={'content'} style={{ height: height - 56, paddingLeft: isXSmall ? 0 : (isSmall ? 20 : 40), paddingRight: isXSmall ? 0 : (isSmall ? 20 : 40) }}
                        >
                            <Switch>
                                <Route path={`${path}/:entity?/:view?/:id?`}>
                                    {component}
                                </Route>
                                <Route path="*">
                                    <NotFound />
                                </Route>
                            </Switch>
                        </div>
                    </ScrollView>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu
                        menuItems={sideMenuItems.map(x => x.items).flat()}
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                        view={view}
                    >
                    </SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3
};