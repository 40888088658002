import { LogLevelClient, LogLevelServer, LogLevels, ApiActions } from '../helpers/Constants';
import { fetchNotAuthorizedData } from '../helpers/Fetch'

class Log {
    generateMessage(level, server, ...args) {
        const isEnabled = (configuredLogLevel, logLevel) => {
            const logLevels = [LogLevels.trace, LogLevels.info, LogLevels.warn, LogLevels.error];
            return logLevels.indexOf(logLevel) !== -1
                && logLevels.indexOf(configuredLogLevel) !== -1
                && logLevels.indexOf(logLevel) >= logLevels.indexOf(configuredLogLevel);
        }

        if (isEnabled(LogLevelClient, level)) {
            if (level === LogLevels.trace) console.log(...args);
            else if (level === LogLevels.info) console.info(...args);
            else if (level === LogLevels.warn) console.warn(...args);
            else if (level === LogLevels.error) console.error(...args);
        }
        if (server && isEnabled(LogLevelServer, level)) {
            try {
                let data = [];
                let stack = (new Error()).stack;
                for (let i = 0; i < args.length; i++) {
                    let message = args[i];
                    // custom serializers
                    /*if (message instanceof Response) {
                        // do something with message
                    }*/
                    data.push({ Level: level, Message: JSON.stringify(message), Stack: stack });
                }
                fetchNotAuthorizedData(ApiActions.Log, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }, 'text').catch((e) => {
                    console.error('Unable to send log to server', e, ...args);
                });
            } catch (e) {
                console.error('Unable to send log to server', e, ...args);
            }
        }
    }

    trace(...args) {
        return this.generateMessage(LogLevels.trace, true, ...args);
    }

    traceClient(...args) {
        return this.generateMessage(LogLevels.trace, false, ...args);
    }

    info(...args) {
        return this.generateMessage(LogLevels.info, true, ...args);
    }

    infoClient(...args) {
        return this.generateMessage(LogLevels.info, false, ...args);
    }

    warn(...args) {
        return this.generateMessage(LogLevels.warn, true, ...args);
    }

    warnClient(...args) {
        return this.generateMessage(LogLevels.warn, false, ...args);
    }

    error(...args) {
        return this.generateMessage(LogLevels.error, true, ...args);
    }

    errorClient(...args) {
        return this.generateMessage(LogLevels.error, false, ...args);
    }
}

export default new Log();
