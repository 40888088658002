import React, { useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../userPanel/UserPanel';
import LanguagePanel from '../languagePanel/LanguagePanel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AuthenticationService } from '../../services'
import { MenuModes } from '../../helpers/Constants'
import { useLocalization } from '../../contexts/LocalizationProvider';
import { Popup } from 'devextreme-react/popup';
import Form, { EmailRule, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import { fetchData } from '../../helpers/Fetch';
import { notify } from '../../utils/Notify';
import Log from '../../utils/Log';
import { DefaultFormOptions } from '../../components/edit/Edit';
import { TextBoxOptions } from '../../components/editors/Editors';
import ButtonLoading from '../../components/buttonLoading/ButtonLoading';

const Header = ({ menuToggleEnabled, title, toggleMenu, menuItems, location }) => {
    const history = useHistory();
    const { t, formatNumber } = useLocalization();
    const { isXSmall } = useScreenSize();
    const login = !AuthenticationService.isLoggedIn() ? { route: { path: 'login' }, text: t(400104) } : {};
    const links = menuItems.concat(login).filter(o => o.route).map(o => (
        <Item
            key={o.route.path}
            widget="dxButton"
            location="after"
            locateInMenu="auto"
            cssClass="toolbar-main-menu-button"
            options={{
                onClick: () => history.push(`/${o.route.path}`),
                type: location.pathname.startsWith(`/${o.route.path}`) ? "default" : "normal",
                stylingMode: "contained",
                text: o.text
            }}
        />
    ));

    const [batchPopupVisible, setBatchPopupVisible] = useState(false);
    const [batchData, setBatchData] = useState({
        batchEmail: null
    });
    const [batchSending, setBatchSending] = useState(false);

    const hideBatchPopup = (e) => {
        if (batchSending) e.cancel = true;
        else {
            setBatchPopupVisible(false);
            setBatchData({
                ...batchData, ...{
                    batchEmail: null
                }
            });
        }
    };

    const doBatch = (e) => {
        const doSubmit = (validation) => {
            const brokenRules = validation.brokenRules.filter(rule => rule.validator.element().offsetParent != null);
            if (brokenRules.length == 0) {
                setBatchSending(true);
                const url = `visusApi/batch?batchEmail=${batchData.batchEmail}`;
                fetchData(url, {}, true, 'text').then(() => {
                    setBatchPopupVisible(false);
                    setBatchData({
                        ...batchData, ...{
                            batchEmail: null
                        }
                    });
                    notify(t(100324), 'success');
                }).catch((error) => {
                    Log.error(error);
                    notify(t(300507), 'error');
                }).finally(() => {
                    setBatchSending(false);
                });
            } else {
                brokenRules[0].validator.focus();
            }
        };

        const validation = e.validationGroup.validate();
        if (validation.complete) {
            validation.complete.then((validation) => {
                doSubmit(validation);
            });
        } else {
            doSubmit(validation);
        }
    }

    const BatchValidationGroup = 'batchValidationGroup'
    const FormOptions = {
        id: 'batchSender',
        showColonAfterLabel: false,
        labelLocation: 'top',
        colCount: 'auto',
        validationGroup: BatchValidationGroup,
    };

    return (
        <header className={'header-component'}>
            <Toolbar className={`header-toolbar${menuToggleEnabled ? '' : ' header-toolbar-no-menu'}`}>
                <Item
                    visible={menuToggleEnabled}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    text={title}
                    visible={!!title}
                />

                <Item
                    location={'after'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button stylingMode="text" text={t(400107)} onClick={() => setBatchPopupVisible(true)} />
                </Item>

                {links}

                {AuthenticationService.isLoggedIn() && <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userListTemplate'}
                    template={'userContextTemplate'}
                    cssClass="toolbar-main-menu-button"
                />}
                <Template name={'userContextTemplate'}>
                    <UserPanel menuMode={MenuModes.Context} />
                </Template>
                <Template name={'userListTemplate'}>
                    <UserPanel menuMode={MenuModes.List} />
                </Template>

                <Item
                    location={'after'}
                    locateInMenu={'never'}
                    //menuItemTemplate={'languageListTemplate'}
                    template={'languageTemplate'}
                    cssClass="toolbar-main-menu-button"
                />
                <Template name={'languageTemplate'}>
                    <LanguagePanel />
                </Template>
            </Toolbar>
            <Popup
                visible={batchPopupVisible}
                onHiding={hideBatchPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={t(400108)}
                width={500}
                height={250}
                fullScreen={isXSmall}
            >
                <Form formData={batchData} {...FormOptions}>
                    <SimpleItem dataField="batchEmail" label={{ text: t(400109) }} editorType="dxTextBox" editorOptions={{ ...TextBoxOptions, ...{ disabled: batchSending } }}>
                        {batchPopupVisible && <RequiredRule message={t(300000)} />}
                        {batchPopupVisible && <EmailRule message={t(300010)} />}
                    </SimpleItem>
                </Form>
                <ButtonLoading
                    onClick={doBatch}
                    type="default"
                    width="auto"
                    text={t(100117)}
                    loading={batchSending}
                    validationGroup="batchValidationGroup"
                    style={{ marginTop: 16, marginRight: 16, marginLeft: 0, marginBottom: 0 }}
                />
            </Popup>
        </header>
    );
};

export default withRouter(Header)

