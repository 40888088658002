import * as React from 'react';
import { Component } from 'react';
import { AuthenticationService } from '../services';
import { Actions, Basename } from '../helpers/Constants';

export default class Logout extends Component {
    static displayName = Logout.name;

    componentDidMount() {
        AuthenticationService.logout(false).then(() => {
            window.location.replace(`${Basename}${Actions.Login}`);
        });
    }

    render() {
        return (
            <></>
        );
    }
}