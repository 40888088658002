import * as React from 'react';
import { Component } from 'react';
import {
    Route,
    Redirect,
} from "react-router-dom";
import { AuthenticationService } from '../../services';
import { QueryParameterNames, Actions, Basename } from '../../helpers/Constants';
import NotAuthorized from '../../views/error/NotAuthorized';


export default class AuthorizeRoute extends Component {
    //subscription = null;

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: this.isLoggedIn(),
            authorized: this.isAuthorized()
        };
    }

    componentDidMount() {
        //this.subscription = AuthenticationService.subscribe(() => this.authenticationChanged());
    }

    componentWillUnmount() {
        //if (this.subscription != null) this.subscription.unsubscribe();
    }

    isLoggedIn() {
        return AuthenticationService.isLoggedIn();
    }

    isAuthorized() {
        return this.props.control === undefined || (Array.isArray(this.props.control) && this.props.control.length == 0) || AuthenticationService.isAuthorized(this.props.control);
    }

    /*authenticationChanged() {
        this.setState({ loggedIn: this.isLoggedIn(), authorized: this.isAuthorized() });
    }*/

    render() {
        const { loggedIn, authorized } = this.state;

        const re = new RegExp(`^(${Basename})`);
        const returnUrl = `${window.location.pathname.replace(re, '')}${window.location.search}${window.location.hash}`;
        //const returnUrl = `${this.props.location.pathname}${this.props.location.search}${this.props.location.hash}`;
        const redirectUrl = `${Actions.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
        const { component: Component, ...rest } = this.props;
        return <Route {...rest}
            render={(props) => {
                if ((!this.props.requireAuthentication || loggedIn) && authorized) {
                    return this.props.render();
                } else if (loggedIn) {
                    return <NotAuthorized />
                } else {
                    return <Redirect to={redirectUrl} />
                }
            }} />
    }
}



