import * as React from 'react';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import './buttonLoading.scss';

export default function ButtonLoading({ text, disabled, loading, children, ...restProps }) {
    return (
        <Button
            className={'button-loading'}
            disabled={loading || disabled}
            {...restProps}
        >
            <LoadIndicator className="button-indicator" height={25} width={25} visible={loading} />
            <span className="dx-button-text">{text}</span>
            {children}
        </Button>    
    );
}