import React, { useCallback } from 'react';
import { useLocalization } from '../../../contexts/LocalizationProvider';
import { Button } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../../contexts/ScreenSizeProvider';
import { Eyes } from '../../../helpers/Constants';
import { confirm } from 'devextreme/ui/dialog';
import './cylinder.scss';

export default function CylinderRemote({ sendMessage, loading, data }) {
    const { t } = useLocalization();
    const { width: screenWidth, height: screenHeight } = useScreenSize();

    const minusAxis = (value) => {
        sendMessage(JSON.stringify({ action: "minusAxis", value: value }));
    }

    const plusAxis = (value) => {
        sendMessage(JSON.stringify({ action: "plusAxis", value: value }));
    }

    const minusVisus = () => {
        sendMessage(JSON.stringify({ action: "minusVisus" }));
    }

    const plusVisus = () => {
        sendMessage(JSON.stringify({ action: "plusVisus" }));
    }

    const done = () => {
        let result = confirm(data.eye == Eyes.Right ? t(100319) : t(100321), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: "nextEye" }));
            }
        });
    }

    const exit = () => {
        let result = confirm(t(100320), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: 'exit' }));
            }
        });
    };

    const width = useCallback(() => {
        let size = Math.min(screenWidth, 450);
        size = Math.max(size, 360);
        return (size - 212);
    }, [screenWidth]);

    return (
        <>
            <Toolbar>
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    options={{ text: t(100311), onClick: exit }} />
            </Toolbar>

            <div id="cylinder-wrapper">
                <div id="eye-wrapper">{data && data.eye == Eyes.Right ? t(100318) : (data.eye == Eyes.Left ? t(100317) : "")}</div>
                <div id="axis-wrapper">
                    <div className="axis-buttons">
                        <Button
                            onClick={() => minusAxis(1)}
                            type="default"
                            text="-1"
                        />
                        <Button
                            onClick={() => minusAxis(5)}
                            type="default"
                            text="-5"
                        />
                        <Button
                            onClick={() => minusAxis(10) }
                            type="default"
                            text="-10"
                        />
                    </div>
                    <div id="axis-value">{data && data.axis !== undefined && data.axis + "°"}</div>
                    <div className="axis-buttons">
                        <Button
                            onClick={() => plusAxis(1) }
                            type="default"
                            text="+1"
                        />
                        <Button
                            onClick={() => plusAxis(5) }
                            type="default"
                            text="+5"
                        />
                        <Button
                            onClick={() => plusAxis(10) }
                            type="default"
                            text="+10"
                        />
                    </div>
                 </div>
                <div id="visus-wrapper">
                    <Button
                        onClick={minusVisus}
                        type="default"
                        icon="minus"
                        disabled={(data && data.disabledMinus) || loading}
                    />
                    <div id="visus-value" style={{ width: width() }}>{data && data.visus !== undefined && data.visus}</div>
                    <Button
                        onClick={plusVisus}
                        type="default"
                        icon="plus"
                        disabled={(data && data.disabledPlus) || loading}
                    />
                </div>
                <Button
                    onClick={done}
                    type="default"
                    width="auto"
                    text={t(401418)}
                    disabled={loading}
                />
            </div>
        </>

    );
}