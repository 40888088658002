import * as React from 'react';
import { useState, useEffect, createContext, useContext, useCallback } from 'react';
// TODO PLM
import skMessages from "./sk.json";
import enMessages from "devextreme/localization/messages/en.json";

/*import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import frMessages from "devextreme/localization/messages/fr.json";
import itMessages from "devextreme/localization/messages/it.json";*/
import {
    locale as baseLocale,
    loadMessages as baseLoadMessages,
    formatDate as baseFormatDate,
    formatNumber as baseFormatNumber,
    formatMessage as baseFormatMessage
} from "devextreme/localization";
import config from 'devextreme/core/config';
import { StorageKeys, DefaultLanguage, DefaultCurrency } from '../helpers/Constants';
import { Miscellaneous } from '../helpers';
import Cookies from 'js-cookie';

const LocalizationContext = createContext({
    locale: null,
    t: (key, value = null) => null,
    formatMessage: (key, value = null) => null,
    formatDate: (value, format) => null,
    formatNumber: (value, format) => null
});
const useLocalization = () => useContext(LocalizationContext);

const locale = () => {
    return baseLocale();
};

function LocalizationProvider({ serverDefaultLocale: serverDefaultLocaleProp, locale: defaultLocale = undefined, availableLocales: defaultAvailableLocales = [], defaultCurrency = undefined, dictionary = undefined, ...props }) {
    const [availableLocales, setAvailableLocales] = useState(defaultAvailableLocales.map(item => item.toLowerCase()));
    const [serverDefaultLocale, setServerDefaultLocale] = useState(serverDefaultLocaleProp.toLowerCase());

    const setLocale = (locale) => {
        let l = checkLocale(locale);
        baseLocale(l);
        saveClientLocale(l);
    };

    const saveClientLocale = (locale) => {
        let l = checkLocale(locale);
        //Cookies.set(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false), l);
        localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false), l);
    };

    const checkLocale = (locale) => {
        let l = locale.toLowerCase();
        if (!availableLocales.includes(l)) l = DefaultLanguage.toLowerCase();
        if (!availableLocales.includes(l)) l = serverDefaultLocale.toLowerCase();
        return l;
    };

    const setDefaultCurrency = (defaultCurrency) => {
        config({ defaultCurrency: defaultCurrency });
        //Cookies.set(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false), defaultCurrency);
        localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false), defaultCurrency);
    };

    const formatMessage = useCallback((key, ...value) => {
        let message = baseFormatMessage(key, value);
        //if (message === '') message = `${locale()}_${key}`;
        if (message === '') message = `${key}`;
        return message;
    }, []);

    const t = (...args) => formatMessage(...args);

    const formatDate = useCallback((value, format) => {
        return baseFormatDate(value, format);
    }, []);

    const formatNumber = useCallback((value, format) => {
        return baseFormatNumber(value, format);
    }, []);

    const formatCurrency = useCallback((value) => {
        // The following properties fall into two groups: minimumIntegerDigits, minimumFractionDigits, and maximumFractionDigits in one group, 
        // minimumSignificantDigits and maximumSignificantDigits in the other.
        // If at least one property from the second group is defined, then the first group is ignored.
        return formatNumber(value, { style: 'currency', currency: defaultCurrency, minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }, []);

    const getDefaultLocale = () => {
        //let defaultLanguage = Cookies.get(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false));
        let defaultLanguage = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false));
        if (defaultLanguage !== null && defaultLanguage !== undefined) return defaultLanguage;
        return DefaultLanguage;
    };

    const getDefaultCurrency = () => {
        //let defaultCurrency = Cookies.get(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false));
        let defaultCurrency = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false));
        if (defaultCurrency !== null && defaultCurrency !== undefined) return defaultCurrency;
        return DefaultCurrency;
    };

    if (defaultLocale === null || defaultLocale === undefined) defaultLocale = getDefaultLocale();
    if (defaultCurrency === null || defaultCurrency === undefined) defaultCurrency = getDefaultCurrency();
    if (dictionary === null || dictionary === undefined) dictionary = {};

    /*baseLoadMessages(deMessages);
    baseLoadMessages(enMessages);
    baseLoadMessages(frMessages);
    baseLoadMessages(itMessages);*/
    baseLoadMessages(skMessages);
    baseLoadMessages(enMessages);
    baseLoadMessages(dictionary);
    setLocale(defaultLocale);
    setDefaultCurrency(defaultCurrency);

    return (
        <LocalizationContext.Provider value={{ t, formatMessage, formatDate, formatNumber, formatCurrency, availableLocales, saveClientLocale }} {...props} />
    );
}

export { LocalizationProvider, useLocalization, LocalizationContext, locale,  }
