import React, { useState } from 'react';
import TileView from 'devextreme-react/tile-view';
import { OptotypeImages } from '../../../helpers/Constants';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useLocalization } from '../../../contexts/LocalizationProvider';
import Tabs from 'devextreme-react/tabs';
import MultiView from 'devextreme-react/multi-view';
import { confirm } from 'devextreme/ui/dialog';
import './optotype.scss';

export default function OptotypeRemote({ sendMessage, loading }) {
    const { t } = useLocalization();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);

    const tabs = [
        { src: "01MC.png" },
        { src: "04BOC.png" },
        { src: "07MC.png" },
        { src: "TE100A.png" },
        { src: "LC100A.png" },
        { src: "NM100A.png" },
        { src: "TY100B.png" }
    ];

    var logicSource = {
        loading: loading,
        selected: selectedImage,
        set: setSelectedImage
    };

    var dataSource = [
        { images: astigmatic, sendMessage: sendMessage, logic: logicSource },
        { images: binocular, sendMessage: sendMessage, logic: logicSource },
        { images: other, sendMessage: sendMessage, logic: logicSource },
        { images: pflueger, sendMessage: sendMessage, logic: logicSource },
        { images: landolt, sendMessage: sendMessage, logic: logicSource },
        { images: numbers, sendMessage: sendMessage, logic: logicSource },
        { images: letters, sendMessage: sendMessage, logic: logicSource },
    ];

    const changeTab = (e) => {
        setSelectedTab(e.itemIndex);
    }

    const exit = () => {
        let result = confirm(t(100320), t(100055));
        result.then((dialogResult) => {
            if (dialogResult) {
                sendMessage(JSON.stringify({ action: 'exit' }));
            }
        });
    };

    const tabRender = (props) => {
        return <div className="tabImage" style={{ backgroundImage: `url(${OptotypeImages.Small + props.src})` }}></div>;
    }

    return (
        <>
            <Toolbar>
                <Item location="after"
                    locateInMenu="always"
                    widget="dxButton"
                    options={{ text: t(100311), onClick: exit }} />
            </Toolbar>
            <div id="optotype-wrapper">
                <Tabs
                    dataSource={tabs}
                    showNavButtons={true}
                    selectedIndex={selectedTab}
                    itemRender={tabRender}
                    onItemClick={changeTab}
                    disabled={loading}
                    cssClass="optotype-tabs"
                />
                <MultiView
                    selectedIndex={selectedTab}
                    dataSource={dataSource}
                    deferRendering={false}
                    itemComponent={ImagesTileView}
                    animationEnabled={false}
                    swipeEnabled={false}
                    disabled={loading}
                />
            </div>
        </>
    );
}

function ImagesTileView(props) {

    const itemRender = (data) => {
        const url = OptotypeImages.Small + data.smallImg;
        return <div className={ props.data.logic.selected == data.bigImg ? "dx-tile-image selected" : "dx-tile-image" } style={{ backgroundImage: `url(${url})`}}></div>;
    }

    const onItemClick = (e) => {
        props.data.logic.set(props.data.images[e.itemIndex].bigImg);
        const message = {
            action: "imageChange",
            value: {
                bigImg: props.data.images[e.itemIndex].bigImg,
                background: props.data.images[e.itemIndex].background,
                resize: props.data.images[e.itemIndex].resize
            }
        };
        props.data.sendMessage(JSON.stringify(message));
    }

    return (
        <>
            <TileView
                baseItemHeight={60}
                baseItemWidth={80}
                itemMargin={5}
                items={props.data.images}
                itemRender={itemRender}
                onItemClick={onItemClick}
                direction="vertical"
                showScrollbar={true}
                disabled={props.data.loading}
                height="100%"
            />
        </>
    );
}

const astigmatic = [
    { smallImg: "01MC.png", bigImg: "01MCBL", background: "black", resize: false },
    { smallImg: "02MC.png", bigImg: "02MCBL", background: "black", resize: false },
    { smallImg: "03MC.png", bigImg: "03MCWH", background: "white", resize: false },
    { smallImg: "04MC.png", bigImg: "04MCBL", background: "black", resize: false },
    { smallImg: "05MC.png", bigImg: "05MCBL", background: "black", resize: false },
    { smallImg: "06MC.png", bigImg: "06MCBL", background: "black", resize: false }
];

const binocular = [
    /*{ smallImg: "01BOG.png", bigImg: "01BORGBL", background: "black", resize: false },
    { smallImg: "02BOG.png", bigImg: "02BORGBL", background: "black", resize: false },
    { smallImg: "03BOG.png", bigImg: "03BORGBL", background: "black", resize: false },
    { smallImg: "04BOG.png", bigImg: "04BORGBL", background: "black", resize: false },
    { smallImg: "05BOG.png", bigImg: "05BORGBL", background: "black", resize: false },
    { smallImg: "06BOG.png", bigImg: "06BORGBL", background: "black", resize: false },
    { smallImg: "07BOG.png", bigImg: "07BORGBLR", background: "black", resize: false },
    { smallImg: "08BOG.png", bigImg: "08BORGBL", background: "black", resize: false },
    { smallImg: "09BOG.png", bigImg: "09BORGBL", background: "black", resize: false },
    { smallImg: "10BOG.png", bigImg: "10BORGBL", background: "black", resize: false },
    { smallImg: "11BOG.png", bigImg: "11BORGBL", background: "black", resize: false },
    { smallImg: "12BOG.png", bigImg: "12BORGBLR", background: "black", resize: false },
    { smallImg: "13BOG.png", bigImg: "13BORGBL", background: "black", resize: false },
    { smallImg: "14BOG.png", bigImg: "14BORGBL", background: "black", resize: false },
    { smallImg: "15BOG.png", bigImg: "15BORGBLR", background: "black", resize: false },
    { smallImg: "16BOG.png", bigImg: "16BORGBL", background: "black", resize: false },
    { smallImg: "17BOG.png", bigImg: "17BORGBL", background: "black", resize: false },
    { smallImg: "18BOG.png", bigImg: "18BORGBL", background: "black", resize: false },
    { smallImg: "19BOG.png", bigImg: "19BORGBL", background: "black", resize: false },
    { smallImg: "20BOG.png", bigImg: "20BORGBL", background: "black", resize: false },
    { smallImg: "21BOG.png", bigImg: "21BORGBL", background: "black", resize: false },
    { smallImg: "22BOG.png", bigImg: "22BORGBL", background: "black", resize: false },
    { smallImg: "23BOG.png", bigImg: "23BORGBLA", background: "black", resize: false },
    { smallImg: "24BOG.png", bigImg: "24BORGBLB", background: "black", resize: false },*/

    { smallImg: "01BOC.png", bigImg: "01BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "02BOC.png", bigImg: "02BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "03BOC.png", bigImg: "03BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "04BOC.png", bigImg: "04BORC", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "05BOC.png", bigImg: "05BORC", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "06BOC.png", bigImg: "06BORC", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "07BOC.png", bigImg: "07BORCR", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "08BOC.png", bigImg: "08BORC", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "09BOC.png", bigImg: "09BORC", background: "rgb(227,227,227)", resize: false },
    //{ smallImg: "10BOC.png", bigImg: "10BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "11BOC.png", bigImg: "11BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "12BOC.png", bigImg: "12BORCR", background: "rgb(227,227,227)", resize: false },
    { smallImg: "13BOC.png", bigImg: "13BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "14BOC.png", bigImg: "14BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "15BOC.png", bigImg: "15BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "16BOC.png", bigImg: "16BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "17BOC.png", bigImg: "17BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "18BOC.png", bigImg: "18BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "19BOC.png", bigImg: "19BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "20BOC.png", bigImg: "20BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "21BOC.png", bigImg: "21BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "22BOC.png", bigImg: "22BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "23BOC.png", bigImg: "23BORC", background: "rgb(227,227,227)", resize: false },
    { smallImg: "24BOC.png", bigImg: "24BORCB", background: "rgb(227,227,227)", resize: false },
    { smallImg: "25BOC.png", bigImg: "25BORC", background: "black", resize: false },
    { smallImg: "26BOC.png", bigImg: "26BORCR", background: "black", resize: false },
    { smallImg: "27BOC.png", bigImg: "27BORC", background: "black", resize: false },
    { smallImg: "28BOC.png", bigImg: "28BORCR", background: "black", resize: false }
];

const other = [
    { smallImg: "07MC.png", bigImg: "07MCBL", background: "black", resize: false },
    { smallImg: "08MC.png", bigImg: "08MCWH", background: "white", resize: false }
];

const pflueger = [
    { smallImg: "TE100A.png", bigImg: "TE100A", background: "black", resize: true },
    { smallImg: "TE100B.png", bigImg: "TE100B", background: "black", resize: true },
    { smallImg: "TE125A.png", bigImg: "TE125A", background: "black", resize: true },
    { smallImg: "TE125B.png", bigImg: "TE125B", background: "black", resize: true },
    { smallImg: "TE160A.png", bigImg: "TE160A", background: "black", resize: true },
    { smallImg: "TE160B.png", bigImg: "TE160B", background: "black", resize: true },
    { smallImg: "TE200A.png", bigImg: "TE200A", background: "black", resize: true },
    { smallImg: "TE200B.png", bigImg: "TE200B", background: "black", resize: true },
    { smallImg: "TE200RG.png", bigImg: "TE200RG", background: "black", resize: true },
    { smallImg: "TE250_320A.png", bigImg: "TE250_320A", background: "black", resize: true },
    { smallImg: "TE250_320B.png", bigImg: "TE250_320B", background: "black", resize: true },
    { smallImg: "TE250_320RG.png", bigImg: "TE250_320RG", background: "black", resize: true },
    { smallImg: "TE400_500A.png", bigImg: "TE400_500A", background: "black", resize: true },
    { smallImg: "TE400_500B.png", bigImg: "TE400_500B", background: "black", resize: true },
    { smallImg: "TE400_500RG.png", bigImg: "TE400_500RG", background: "black", resize: true },
    { smallImg: "TE630A.png", bigImg: "TE630A", background: "black", resize: true },
    { smallImg: "TE630B.png", bigImg: "TE630B", background: "black", resize: true },
    { smallImg: "TE630RG.png", bigImg: "TE630RG", background: "black", resize: true },
    { smallImg: "TE800_1000A.png", bigImg: "TE800_1000A", background: "black", resize: true },
    { smallImg: "TE800_1000B.png", bigImg: "TE800_1000B", background: "black", resize: true },
    { smallImg: "TE800_1000RG.png", bigImg: "TE800_1000RG", background: "black", resize: true },
    { smallImg: "TE1250_2000.png", bigImg: "TE1250_2000", background: "black", resize: true },
    { smallImg: "TE1250_2000RG.png", bigImg: "TE1250_2000RG", background: "black", resize: true }
];

const landolt = [
    { smallImg: "LC100A.png", bigImg: "LC100A", background: "black", resize: true },
    { smallImg: "LC100B.png", bigImg: "LC100B", background: "black", resize: true },
    { smallImg: "LC125A.png", bigImg: "LC125A", background: "black", resize: true },
    { smallImg: "LC125B.png", bigImg: "LC125B", background: "black", resize: true },
    { smallImg: "LC160A.png", bigImg: "LC160A", background: "black", resize: true },
    { smallImg: "LC160B.png", bigImg: "LC160B", background: "black", resize: true },
    { smallImg: "LC200A.png", bigImg: "LC200A", background: "black", resize: true },
    { smallImg: "LC200B.png", bigImg: "LC200B", background: "black", resize: true },
    { smallImg: "LC200RG.png", bigImg: "LC200RG", background: "black", resize: true },
    { smallImg: "LC250_320A.png", bigImg: "LC250_320A", background: "black", resize: true },
    { smallImg: "LC250_320B.png", bigImg: "LC250_320B", background: "black", resize: true },
    { smallImg: "LC250_320RG.png", bigImg: "LC250_320RG", background: "black", resize: true },
    { smallImg: "LC400_500A.png", bigImg: "LC400_500A", background: "black", resize: true },
    { smallImg: "LC400_500B.png", bigImg: "LC400_500B", background: "black", resize: true },
    { smallImg: "LC400_500RG.png", bigImg: "LC400_500RG", background: "black", resize: true },
    { smallImg: "LC630A.png", bigImg: "LC630A", background: "black", resize: true },
    { smallImg: "LC630B.png", bigImg: "LC630B", background: "black", resize: true },
    { smallImg: "LC630RG.png", bigImg: "LC630RG", background: "black", resize: true },
    { smallImg: "LC800_1000A.png", bigImg: "LC800_1000A", background: "black", resize: true },
    { smallImg: "LC800_1000B.png", bigImg: "LC800_1000B", background: "black", resize: true },
    { smallImg: "LC800_1000RG.png", bigImg: "LC800_1000RG", background: "black", resize: true },
    { smallImg: "LC1250_2000.png", bigImg: "LC1250_2000", background: "black", resize: true },
    { smallImg: "LC1250_2000RG.png", bigImg: "LC1250_2000RG", background: "black", resize: true }
];

const numbers = [
    { smallImg: "NM100A.png", bigImg: "NM100A", background: "black", resize: true },
    { smallImg: "NM100B.png", bigImg: "NM100B", background: "black", resize: true },
    { smallImg: "NM125A.png", bigImg: "NM125A", background: "black", resize: true },
    { smallImg: "NM125B.png", bigImg: "NM125B", background: "black", resize: true },
    { smallImg: "NM160A.png", bigImg: "NM160A", background: "black", resize: true },
    { smallImg: "NM160B.png", bigImg: "NM160B", background: "black", resize: true },
    { smallImg: "NM200A.png", bigImg: "NM200A", background: "black", resize: true },
    { smallImg: "NM200B.png", bigImg: "NM200B", background: "black", resize: true },
    { smallImg: "NM200RG.png", bigImg: "NM200RG", background: "black", resize: true },
    { smallImg: "NM250_320A.png", bigImg: "NM250_320A", background: "black", resize: true },
    { smallImg: "NM250_320B.png", bigImg: "NM250_320B", background: "black", resize: true },
    { smallImg: "NM250_320RG.png", bigImg: "NM250_320RG", background: "black", resize: true },
    { smallImg: "NM400_500A.png", bigImg: "NM400_500A", background: "black", resize: true },
    { smallImg: "NM400_500B.png", bigImg: "NM400_500B", background: "black", resize: true },
    { smallImg: "NM400_500RG.png", bigImg: "NM400_500RG", background: "black", resize: true },
    { smallImg: "NM630A.png", bigImg: "NM630A", background: "black", resize: true },
    { smallImg: "NM630B.png", bigImg: "NM630B", background: "black", resize: true },
    { smallImg: "NM630RG.png", bigImg: "NM630RG", background: "black", resize: true },
    { smallImg: "NM800_1000A.png", bigImg: "NM800_1000A", background: "black", resize: true },
    { smallImg: "NM800_1000B.png", bigImg: "NM800_1000B", background: "black", resize: true },
    { smallImg: "NM800_1000RG.png", bigImg: "NM800_1000RG", background: "black", resize: true },
    { smallImg: "NM1250_2000.png", bigImg: "NM1250_2000", background: "black", resize: true },
    { smallImg: "NM1250_2000RG.png", bigImg: "NM1250_2000RG", background: "black", resize: true }
];

const letters = [
    { smallImg: "TY100A.png", bigImg: "TY100A", background: "black", resize: true },
    { smallImg: "TY100B.png", bigImg: "TY100B", background: "black", resize: true },
    { smallImg: "TY125A.png", bigImg: "TY125A", background: "black", resize: true },
    { smallImg: "TY125B.png", bigImg: "TY125B", background: "black", resize: true },
    { smallImg: "TY160A.png", bigImg: "TY160A", background: "black", resize: true },
    { smallImg: "TY160B.png", bigImg: "TY160B", background: "black", resize: true },
    { smallImg: "TY200A.png", bigImg: "TY200A", background: "black", resize: true },
    { smallImg: "TY200B.png", bigImg: "TY200B", background: "black", resize: true },
    { smallImg: "TY200RG.png", bigImg: "TY200RG", background: "black", resize: true },
    { smallImg: "TY250_320A.png", bigImg: "TY250_320A", background: "black", resize: true },
    { smallImg: "TY250_320B.png", bigImg: "TY250_320B", background: "black", resize: true },
    { smallImg: "TY250_320RG.png", bigImg: "TY250_320RG", background: "black", resize: true },
    { smallImg: "TY400_500A.png", bigImg: "TY400_500A", background: "black", resize: true },
    { smallImg: "TY400_500B.png", bigImg: "TY400_500B", background: "black", resize: true },
    { smallImg: "TY400_500RG.png", bigImg: "TY400_500RG", background: "black", resize: true },
    { smallImg: "TY630A.png", bigImg: "TY630A", background: "black", resize: true },
    { smallImg: "TY630B.png", bigImg: "TY630B", background: "black", resize: true },
    { smallImg: "TY630RG.png", bigImg: "TY630RG", background: "black", resize: true },
    { smallImg: "TY800_1000A.png", bigImg: "TY800_1000A", background: "black", resize: true },
    { smallImg: "TY800_1000B.png", bigImg: "TY800_1000B", background: "black", resize: true },
    { smallImg: "TY800_1000RG.png", bigImg: "TY800_1000RG", background: "black", resize: true },
    { smallImg: "TY1250_2000.png", bigImg: "TY1250_2000", background: "black", resize: true },
    { smallImg: "TY1250_2000RG.png", bigImg: "TY1250_2000RG", background: "black", resize: true }
];