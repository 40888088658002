import * as React from 'react';
import { Component } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { LocalizationContext } from '../../contexts/LocalizationProvider';
import { Basename } from '../../helpers/Constants';
import './error.scss'

export default class Error extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        window.location.replace(Basename);
    }

    render() {
        return (
            <ScrollView height={'100%'} width={'100%'} className={'error-card-scroll-view'}>
                <div className={'error-card'}>
                    <div style={{ textAlign: 'center' }}>
                        {this.props.code && <h1>{this.props.code}</h1>}
                        <h4>{this.props.description}</h4>
                        <div>{this.props.text}</div>
                        <Button
                            width='auto'
                            text={this.props.buttonText || this.context.t(100203)}
                            type="default"
                            stylingMode="outlined"
                            onClick={this.onClick}
                        />
                    </div>
                </div>
            </ScrollView>           
        );
    }
}
Error.contextType = LocalizationContext;