import { fetchData } from '../helpers/Fetch';
import { CreditRefreshInterval } from '../helpers/Constants';

export const startCreditWatcher = (updateCredit) => {
    forceRefreshCredit(updateCredit);
    return setInterval(() => {
        forceRefreshCredit(updateCredit);
    }, CreditRefreshInterval);
}

export const stopCreditWatcher = (handle) => {
    clearInterval(handle);
}

export const forceRefreshCredit = (updateCredit) => {
    fetchData('userApi/getCredit').then((credit) => {
        updateCredit(credit);
    });
}