import React, { useEffect } from 'react';
import List from 'devextreme-react/list';
import './userPanel.scss';
import { AuthenticationService } from '../../services'
import { Actions, QueryParameterNames, MenuModes, Controls } from '../../helpers/Constants'
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalization } from '../../contexts/LocalizationProvider';
import DropDownButton from 'devextreme-react/drop-down-button';
import Button from 'devextreme-react/button';
import { startCreditWatcher, stopCreditWatcher, forceRefreshCredit } from '../../services/RefreshService';
import { connect } from "react-redux";
import { updateCredit } from "../../redux/Actions";

function UserPanel({ menuMode, updateCredit, credit }) {
    const history = useHistory();
    const location = useLocation();
    const { t, formatCurrency } = useLocalization();


    const search = new URLSearchParams(location.search);
    search.delete(QueryParameterNames.ReturnUrl);
    const returnUrl = `${location.pathname}?${search.toString()}${location.hash}`;

    useEffect(() => {
        forceRefreshCredit(updateCredit);
    });

    useEffect(() => {
        const refreshInterval = startCreditWatcher(updateCredit);
        return () => {
            stopCreditWatcher(refreshInterval);
        };
    }, []);

    const name = `${AuthenticationService.currentUser.lastName}, ${AuthenticationService.currentUser.firstName}${credit == null ? '' : ` (${formatCurrency(credit)})`}`;

    const isActive = (action) => {
        return location.pathname.startsWith(action);
    }

    const items = [{
        text: t(400800),
        navigate: () => history.push(`${Actions.Calibration}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`),
        active: isActive(Actions.Calibration)
    }, {
        text: t(401700),
        navigate: () => history.push(`${Actions.Company}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`),
        active: isActive(Actions.Company),
        visible: AuthenticationService.isAuthorized(Controls.Company)
    }, {
        text: t(100057),
        navigate: () => history.push(Actions.Logout),
        active: false
    }];

    return menuMode == MenuModes.Context ? <ContextUserPanel items={items} name={name} /> : <ListUserPanel items={items} name={name} />
}

const mapStateToProps = (state, ownProps) => {
    const { value } = state;
    return { ...ownProps, credit: value };
};

export default connect(
    mapStateToProps,
    { updateCredit }
)(UserPanel);

function ContextUserPanel({ items, name }) {
    return (
        <DropDownButton
            type="normal"
            stylingMode="contained"
            icon="user"
            text={name}
            items={items}
            onItemClick={(e) => {
                if (!e.itemData.active) {
                    e.itemData.navigate();
                }
            }}
            itemRender={(item) => {
                return <div className={item.active?'active':''} >{item.text}</div>
            }}
        />
    );
}

function ListUserPanel({ items, name }) {
    const { t, formatCurrency } = useLocalization();
    return (
        <List
            className="user-menu-list"
            focusStateEnabled={false}
            grouped={true}
            collapsibleGroups={true}
            groupRender={(item) => <div>{item.key}</div>}
            itemRender={(item) => {
                return <Button
                    type={item.active ? 'default' : 'normal'} 
                    stylingMode="contained"
                    text={item.text}
                    style={{ width: '100%', height: 36, textAlign: 'left' }}
                />
            }}
            onGroupRendered={(e) => e.component.collapseGroup(e.groupIndex)}
            onItemClick={(e) => {
                if (!e.itemData.active) {
                    e.itemData.navigate();
                }
            }}
            dataSource={[
                {
                    key: name,
                    items: items
                }
            ]}
        />
    );
}