import React from 'react';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import Toolbar, { Item } from 'devextreme-react/toolbar';

import './contentTitle.scss';

export default function ContentTitle(props) {
    const { isXSmall, isSmall } = useScreenSize();

    const renderLabel = () => {
        return (
            <h5 style={{ margin: 0 }}>{props.title}</h5>    
        );
    };

    return (
        <Toolbar
            elementAttr={{ class: 'content-title' }}
            style={{ backgroundColor: 'transparent', paddingLeft: (isXSmall ? 20 : 0), paddingRight: (isXSmall ? 20 : 0) }}
            height={isXSmall ? 56 : (isSmall ? 66 : 76)}
        >
            <Item location="before"
                locateInMenu="never"
                render={renderLabel} />
            {props.children}
        </Toolbar>
    );
}